import React, { Component } from "react";
import Carousel from 'react-bootstrap/Carousel';
// import mountains from './../img/slide_1.jpg';
// import waterResort from './../img/slide_2.jpg';
// import camping from './../img/slide_3.jpg';
import {cloudfrontUrl} from './../constants';
function CarouselFade() {
  return (
    <div id='carousel-div'>
    <Carousel id='carousel' variant='dark'>
      <Carousel.Item >
        <img
          className="d-block w-100 h-20"
          src={cloudfrontUrl+"images/miscellaneous/slide_1.jpg"}
          style={{ height:'400px'}}
        />
        <Carousel.Caption>
          <h3 className='carousel-h3'>Mountains</h3>
          {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 h-10"
          src={cloudfrontUrl+"images/miscellaneous/slide_2.jpg"}
          style={{ height:'400px'}}
        />
        <Carousel.Caption>
          <h3 className='carousel-h3'>Water Resort</h3>
         
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 h-10"
          src={cloudfrontUrl+"images/miscellaneous/slide_3.jpg"}
          alt="Third slide"
          style={{ height:'400px'}}
        />

        <Carousel.Caption>
          <h3 className='carousel-h3'>Camping</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  );
}

export default CarouselFade;