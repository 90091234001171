import React, { Component } from "react";
import { Container, Row, CardColumns, Badge } from "reactstrap";
import TourCard from "./TourCard";
import "../App.css";

const tours = [
  {
    id: 1,
    category: ["beach","desert"],
    img: "images/dubai_7d/dubai_7d.jpg",
    alt: "",
    title: "Dubai 7D-6N"
   
  },
  {
    id: 2,
    category: ["beach","desert"],
    img: "images/dubai_5d/dubai_5d.jpg",
    alt: "",
    title: "Dubai 5D-4N"
   
  },
  {
    id: 3,
    category: ["resort", "honeymoon"],
    img: "images/maldives/maldives.jpg",
    alt: "",
    title: "Maldives"
  },
  {
    id: 4,
    category: ["beach","honeymoon"],
    img: "images/bangkok/bangkok.jpg",
    alt: "",
    title: "Bangkok"
   
  },
  {
    id: 5,
    category: ["beach","mountain"],
    img: "images/kerala-tour/kerala-main.jpg",
    alt: "",
    title: "Kerala"
   
  }
];

const itemCategories = [
  "all",
  "beach",
  "desert",
  "honeymoon",
  "mountain"
];

class Package extends Component {
  
  state = {
    cards: [],
    category: "all"
  };

  componentDidMount() {
    this.setState({ cards: tours });
  }
  

  render() {
    const { cards, category } = this.state;
    return (
      <div className="subComponent-lg" id="packageBody">
        <Container>
          <header className="headerTitle text-center">
            <h1>Tour Packages</h1>
            <p >A Great Collection of Our Tour Packages</p>
          </header>
          <section className="packageBody text-center">
            {itemCategories.map((badge, index) => (
              <Badge
                key={index}
                href=""
                color={badge === category ? "dark" : "pink"}
                onClick={() => this.setState({ category: badge })}
              >
                {badge}
              </Badge>
            ))}

            <Row className="text-left">
              <CardColumns>
                {category !== "all"
                  ? cards.map(tourcard => {
                    return tourcard.category.map(catItem => {
                      return catItem === category ? (
                        <TourCard key={tourcard.id} tourcard={tourcard} />
                      ) : null;
                    });
                  })
                  : cards.map(tourcard => (
                    <TourCard key={tourcard.id} tourcard={tourcard} />
                  ))}
              </CardColumns>
            </Row>
          </section>
        </Container>
      </div>
    );
  }
}

export default Package;
