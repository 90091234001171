import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "./../App.css";


import { useParams } from 'react-router-dom';
import packagesFile from './../packages.json';
import validate from "validate.js";
import PhoneInput from "react-phone-input-2";
import { ContactHttpService } from "./../services/contactHttp";
import NavbarTour from "../components/NavbarTour";
import BookNow from "../components/BookNow";
import {cloudfrontUrl} from './../constants';
const Tour = (props) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  // const images = require.context('./../../public', true,/\.(png|jpe?g|svg)$/);

  const { id } = useParams();
  console.log(id);
  const packageData = packagesFile[id];
  const packageDetails = Object.keys(packageData.Details);
  // let imgsrcMain = images(`./${packageData['MainImage']}`);
  const httpService = new ContactHttpService();
  const sendMessageLogic = () => {
    let phoneRegex = new RegExp(/^\+(?:[0-9] ?){7,14}[0-9]$/);
    if ((!username.match(/[\W0-9]/) && username !== "") &&
      (validate.validators.email.PATTERN.test(email) && email !== "") &&
      (phoneRegex.test(phone)) && (message !== "")) {
      let postData = {
        name: username,
        email: email,
        mobile: phone,
        message: message,
        packageName: id
      };
      httpService.postQuery(postData).then((resp) => {
        console.log(resp);
        if (resp.status === 200 && resp.data.status === 200) {
          alert(resp.data.message);
          handlerReset();
        } else {
          alert('Please try again after sometime!!!');
        }
      })

    }
    else {
      alert("You have not yet completed the form");
    }
  }
  const handlerReset = () => {
    setMessage("");
    setEmail("");
    setUsername("");
    setPhone("+91");
   
  }
  const scrollToContent = () => {
    const contentSection = document.getElementById("contentSection");
    if (contentSection) {
      window.scrollTo({
        top: contentSection.offsetTop,
        behavior: "smooth",
      });
    }};
  return (
    <div>
      {/* <NavbarMain></NavbarMain> */}
      <NavbarTour></NavbarTour>
      <div id="contentSection" className="subComponent">
        <Container style={{backgroundColor:"#e0e0e0"}}>
          <section className="tour-cover item-center">
            <img src={cloudfrontUrl+packageData['MainImage']} alt="" />
            <h1 style={{padding:"10px"}}>{id}</h1>
            {/* <h4>Batangas Resort</h4> */}
          </section>
      
          <section className="tour-info">
            <Row>
              <Col sm="8">
              <div style={{padding:"10px"}}>
                { packageData["About Tour"]!=="" && packageData["About Tour"]}
                </div>
                <h5 style={{ fontStyle: "italic" }}>{packageData.Tagline}</h5>
                <div className="tour-desc">
                  {
                    packageDetails.map((day, id) => (
                      <div id={id}>
                        <h6>{day}:</h6>
                        <ul>

                          {packageData['Details'][day].map((features,idx) => (
                            <li key={idx}>{features}</li>
                          ))}
                        </ul>
                      </div>
                    ))

                  }

                </div>
              </Col>
              <Col sm="4">
   
                <div style={{textAlign:"center", fontSize:"18px", padding:"10px"}}>
                 <b> (Excluding Flight Tickets) Price: <div style={{textDecoration: packageData["OffPrice"]?"line-through":null}}>{ packageData["Price"]}</div><div><sup>{packageData["OffPercentage"]?packageData["OffPercentage"]:null}</sup></div><div>{packageData["OffPrice"]?packageData["OffPrice"]:null}</div></b> 
                </div>
                <div>
                  <h6><b>{packageData["PriceNote"]?packageData["PriceNote"]:null}</b></h6>
                </div>
             <BookNow></BookNow>
                <div className="tour-gallery">
                  {
                    packageData["Images"].map((pic,idx)=>(
                      
                      <a href={cloudfrontUrl+pic} key={idx}>
                    <img src={cloudfrontUrl+pic} alt="" style={{objectFit:"fill",padding:"4px"}}/>
                  </a>
                    ))
                  }
                  
                </div>
                <div style={{padding:"25px"}}>
                  <h4 style={{textAlign:"center"}}><b>{packageData['Inclusions'].length!==0?'Inclusions':null}</b></h4>
                  <ul>

                          {packageData['Inclusions'].map((features,idx) => (
                            <li key={idx}>{features}</li>
                          ))}
                        </ul>
                </div>
                <div style={{padding:"25px"}}>
                  <h4 style={{textAlign:"center"}}><b>{packageData['Optionals'].length!==0?'Optionals':null}</b></h4>
                  <ul>

                          {packageData['Optionals'].map((features,idx) => (
                            <li key={idx}>{features}</li>
                          ))}
                        </ul>
                </div>
              </Col>
            </Row>
          </section>
          <div>
            <b>*Note</b>:
            {packageData['Note']}
          </div>
        </Container>
        <section className="reviews">
          <Container>
            <section className="tour-msg text-center">
              <h1>Know more about the Package</h1>
              <p>We're glad to hear something from you.</p>
              <form action="">
                <Row>
                  <Col sm="6">
                    <input
                      type="text"
                      name="Name"
                      id="reviewer-name"
                      placeholder="Your Name"
                      required
                      value={username}
                      onChange={(evt) => setUsername(evt.target.value)}
                    />
                    <br />
                    <input
                      type="email"
                      name="Email"
                      id="reviewer-email"
                      placeholder="Your email"
                      required
                      value={email}
                      onChange={(evt) => setEmail(evt.target.value)}
                    />
                    <br />
                    <PhoneInput
                      country={"in"}
                      className="marginBottom"
                      value={phone}
                      onChange={(evt) => setPhone('+' + evt)}
                      specialLabel=""
                      inputStyle={{ background: 'rgba(255, 255, 255, 0.3)' }}
                    />
                  </Col>
                  <Col>
                    <textarea
                      name="Message"
                      id="reviewer-message"
                      rows="4"
                      placeholder="Your Message"
                      value={message}
                      onChange={(evt) => setMessage(evt.target.value)}
                    />
                  </Col>
                </Row>
                <Button outline color="secondary" className="float-right" onClick={sendMessageLogic}>
                  Submit
                </Button>
              </form>
            </section>
          </Container>
        </section>
        <footer>
    <p>&copy; 2023 VacySphere. All rights reserved.</p>
  </footer>
      </div>
      <div className="scroll-indicator" onClick={scrollToContent}>
        <span></span>
      </div>
    </div>
  )
};

export default Tour;
