import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';



const BookNow = () => {
  const handleBookNowClick = () => {
    // Replace 'https://your-booking-link.com' with your actual booking link
    window.open("https://bit.ly/vacysphere-booking-form", "_blank");
  };
  const handleWhatsAppClick = () => {
    // Replace 'whatsapp://send?phone=1234567890' with your actual WhatsApp number
    window.open("https://wa.me/message/ZBR6GYZK5A4XC1", "_blank");
  };
  return (
    <section className="book-now">
      <div className="book-now-content">
        <h2>Ready to Book Your Adventure?</h2>
        <p>Secure your spot now and embark on an unforgettable journey.</p>
        <Button color="primary" onClick={handleBookNowClick}>
          Book Now
        </Button>
        <button className="whatsapp-button" onClick={handleWhatsAppClick}>
          <FontAwesomeIcon icon={faWhatsapp} />
        </button>
    
   
 
      </div>
    </section>
  );
};

export default BookNow;
