import axios from 'axios';
import {contactUrl} from './../constants';
export class ContactHttpService{
    postQuery(data){
        let response = axios.post(contactUrl,data,{
            headers:{
                'Content-Type': 'application/json'
            }
        });
        return response;
    }
}