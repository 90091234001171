import React from "react";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button
} from "reactstrap";
import "../App.css";
import { Redirect, useNavigate,useH, Link} from "react-router-dom";
import {cloudfrontUrl} from './../constants';
// const openTour=(id)=>{
//   useNavigate(`/tour/{id}`);
// }
const TourCard = (props) => {
  // const { MainImage, alt, title, subtitle } = props.tourcard;
  const { img, alt, title, subtitle } = props.tourcard;
  // const navigate = useNavigate();
  // const navigateToTour = (tourTitle) => {
  //   // 👇️ navigate to /contacts
  //   navigate(`/tour/&{tourTitle}`);
  // };

  return (
    <Card>
      <CardImg top width="100%" src={cloudfrontUrl+img} alt={alt} />
      <CardBody>
       <Link className="float-right" to={`/tour/${title}`}>Read more</Link>
        {/* <Button outline color="secondary" className="float-right" onClick={<Redirect to={`/tour/${title}`}></Redirect>} >
          Read more
        </Button> */}
      
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
      </CardBody>
    </Card>
  );
};
export default TourCard;
