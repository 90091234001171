import React from "react";
import ReactDOM from "react-dom";
import App from "./App";


import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Add custom css import below this line
import "./index.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);



