import React, { Component } from "react";
import "./App.css";
// import { Router, Link } from "@reach/router";

import Home from "./pages/Home";
import Tour from "./pages/Tour";
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' Component={Home}></Route>
          <Route path='/tour/:id' Component={Tour}></Route>
        </Routes>
      
        
      </div>
      </Router>
    );
  }
}

export default App;
