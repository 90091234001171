import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "../App.css";
import { ReactComponent as Phone } from "../assets/svg/phone.svg";
import { ReactComponent as MapPin } from "../assets/svg/map-pin.svg";
import { ReactComponent as Mail } from "../assets/svg/mail.svg";
import PhoneInput from "react-phone-input-2";
import { ContactHttpService } from './../services/contactHttp';
import validate from "validate.js";


class Contact extends Component {
  constructor(props) {
    super(props);
    // this.options = countryList().getData();
    this.state = {
      message: "",
      phone: "",
      complete: "",
      displayComplete: "none",
      name: "",
      email: "",
      isNameValidated: false,
      isEmailValidated: false,
      isPhoneValidated: false,
      isMessageValidated: false
    };


    this.flagsRef = React.createRef();

    this.handleValidateName = this.handleValidateName.bind(this);

    this.handlerValidateEmail = this.handlerValidateEmail.bind(this);
    this.handlerValidateMessage = this.handlerValidateMessage.bind(this);

    this.handlerComplete = this.handlerComplete.bind(this);
    this.handlerReset = this.handlerReset.bind(this);
    this.completeForm = this.completeForm.bind(this);
    this.httpService = new ContactHttpService();

  }
  handleValidateName(e) {
    this.setState({ name: e.target.value });
    !e.target.value.match(/[\W0-9]/) && e.target.value !== ""
      ? (this.setState({ isNameValidated: true }))
      : (this.setState({ isNameValidated: false }));
    console.log(this.state.isNameValidated);
  }
  handlerValidateEmail(e) {
    this.setState({
      email: e.target.value
    });
    // console.log(validate.validators.email.PATTERN);
    validate.validators.email.PATTERN.test(e.target.value)
      ? (this.setState({ isEmailValidated: true }))
      : (this.setState({ isEmailValidated: false }));
    if (e.target.value === "") this.setState({ isEmailValidated: false });
  }
  handlerValidatePhone(e) {
    e='+'+e;
    console.log("e",e);
    this.setState({
      phone: e
    });
    let regex = new RegExp(/^\+(?:[0-9] ?){7,14}[0-9]$/);
    if (e === null) (this.setState({ isPhoneValidated: false }));
    if (regex.test(e) == true) {
      this.setState({ isPhoneValidated: true });
    } else {
      this.setState({ isPhoneValidated: false })
    }

    console.log(this.state.isPhoneValidated);
  }
  handlerValidateMessage(e) {
    this.setState({ message: e.target.value });
    e.target.value === ""
      ? (this.setState({ isMessageValidated: false }))
      : (this.setState({ isMessageValidated: true }));
  }
  handlerComplete(e) {
    if (
      true
    ) {
      this.setState({
        displayComplete: "flex"
      });
      window.scroll({
        top: this.complete.offsetTop,
        left: 0,
        behavior: "smooth"
      });
    } else {
      alert("You have not yet completed the form");
    }
  }
  handlerReset() {
    this.setState({
      message: "",
      phone: "+91",
      complete: "",
      displayComplete: "none",
      name: "",
      email: "",
      isNameValidated: false,
      isEmailValidated: false,
      isPhoneValidated: false,
      isMessageValidated: false

    });
    // this.nameCircle.className = "";

    // this.emailCircle.className = "";

    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth"
    // });
  }
  completeForm() {
    if (
      this.state.isNameValidated === true &&
      this.state.isEmailValidated === true &&
      this.state.isPhoneValidated === true &&
      this.state.isMessageValidated === true
    ) {
      let postData = {
        name: this.state.name,
        email: this.state.email, 
        mobile:this.state.phone,
        message:this.state.message
      };
      this.httpService.postQuery(postData).then((resp)=>{
        console.log(resp);
        if (resp.status==200 && resp.data.status==200){
          alert(resp.data.message);
          this.handlerReset();
        }else {
          alert('Please try again after sometime!!!');
         }
      })
    }else{
      alert("You have not yet completed the form");
    } 
  }
  render() {
    try {
      return (
        <div className="subComponent-lg" id="contactBody">
          <Container>
            <header className="headerTitle text-center">
              <h1>Contact</h1>
              <p>GET IN TOUCH WITH US FOR ANY QUERY OR TO GET A CUSTOMISED PACKAGE</p>
            </header>
            <section className="svg-group text-center">
              <Row>
                <Col  >
                  <div className="svg-card-3">
                    <Phone width="50" height="55" strokeWidth="1" />
                    <p>+91-9589799028 / +91-6232477655</p>
                  </div>
                </Col>
                {/* <Col lg="4" md="4">
                <div className="svg-card-3">
                  <MapPin width="55" height="55" strokeWidth="1" />
                  <p>Manila City, Philippines</p>
                </div>
              </Col> */}
                <Col  >
                  <div className="svg-card-3">
                    <Mail width="55" height="55" strokeWidth="1" />
                    <p>info@vacysphere.com / vacysphere@gmail.com</p>
                  </div>
                </Col>
              </Row>
            </section>
            <hr />
            <br />
            <section className="msg text-center">
              <form >
                <Row>
                  <Col sm="6">

                    <input
                      type="text"
                      name="Name"
                      pattern="[A-Za-z]"
                      id="reviewer-name"
                      placeholder="Your Name"
                      required
                      value={this.state.name}
                      onChange={this.handleValidateName}

                    />

                    <br />
                    <input
                      type="email"
                      name="Email"
                      id="reviewer-email"
                      placeholder="Your email"
                      required
                      onChange={this.handlerValidateEmail}
                      value={this.state.email}
                    />
                    <br />
                    <PhoneInput
                      country={"in"}
                      className="marginBottom"
                      value={this.state.phone}
                      onChange={phone =>this.handlerValidatePhone(phone)}
                      specialLabel=""
                      inputStyle={{ background: 'rgba(255, 255, 255, 0.3)' }}
                     
                    />
                    {/* <input
                    type="tel"
                    name="Mobile"
                    id="reviewer-mobile"
                    placeholder="Your mobile"
                    required
                    minLength="10"
                    
                  /> */}
                  </Col>
                  <Col>

                    <textarea
                      name="Message"
                      id="reviewer-message"
                      rows="4"
                      placeholder="Your Message"
                      onChange={this.handlerValidateMessage}
                      value={this.state.message}
                    />
                    <Button outline color="light" className="float-left" onClick={this.completeForm}>
                      Send Message
                    </Button>
                  </Col>
                </Row>
              </form>
            </section>
          </Container>
        </div>
      );
    } catch (e) {
      return (<div>{e}</div>);
    }
  }
}

export default Contact;
